import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export interface ModalSliceState {
  isProRequiredModal: boolean;
  isOpenModeModal: boolean;
  isOpenLanguageModal: boolean;
  isOpenCookieModal: boolean;
  isOpenPlanModal: boolean;
  isOpenBannerModal: boolean;
  isOpenMiniDrawer: boolean;
  isOpenSettingsDrawer: boolean;
  isOpenConfirmModal: boolean;
  isOpenConfirmAddressModal: boolean;
  isOpenBrushWidget: boolean;
  isOpenProgressModal: boolean;
  isOpenBuyProModal: boolean;
  isOpenInstructionModal: boolean;
  isOpenBuyProPromoModal: boolean;
}

const initialState: ModalSliceState = {
  isProRequiredModal: false,
  isOpenModeModal: false,
  isOpenLanguageModal: false,
  isOpenCookieModal: !(
    !!Cookies.get('cookies_accepted') || !!Cookies.get('cookies_declined')
  ),
  isOpenPlanModal: false,
  isOpenBannerModal: false,
  isOpenMiniDrawer: true,
  isOpenSettingsDrawer: false,
  isOpenConfirmModal: false,
  isOpenConfirmAddressModal: false,
  isOpenBrushWidget: false,
  isOpenProgressModal: false,
  isOpenBuyProModal: false,
  isOpenInstructionModal: false,
  isOpenBuyProPromoModal: false,
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setProRequiredModal: (state, action: PayloadAction<boolean>) => {
      state.isProRequiredModal = action.payload;
    },
    setIsOpenModeModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModeModal = action.payload;
    },
    setIsOpenLanguageModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenLanguageModal = action.payload;
    },
    setIsOpenCookieModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenCookieModal = action.payload;
    },
    setIsOpenPlanModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenPlanModal = action.payload;
    },
    setIsOpenBannerModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenBannerModal = action.payload;
    },
    setIsOpenMiniDrawer: (state, action: PayloadAction<boolean>) => {
      state.isOpenMiniDrawer = action.payload;
    },
    setIsOpenSettingsDrawer: (state, action: PayloadAction<boolean>) => {
      state.isOpenSettingsDrawer = action.payload;
    },
    setIsOpenConfirmModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenConfirmModal = action.payload;
    },
    setIsOpenConfirmAddressModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenConfirmAddressModal = action.payload;
    },
    setIsOpenBrushWidget: (state, action: PayloadAction<boolean>) => {
      state.isOpenBrushWidget = action.payload;
    },
    setIsOpenProgressModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenProgressModal = action.payload;
    },
    setIsOpenBuyProModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenBuyProModal = action.payload;
    },
    setIsOpenBuyProPromoModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenBuyProPromoModal = action.payload;
    },
  },
});

export const {
  setProRequiredModal,
  setIsOpenModeModal,
  setIsOpenPlanModal,
  setIsOpenLanguageModal,
  setIsOpenCookieModal,
  setIsOpenBannerModal,
  setIsOpenMiniDrawer,
  setIsOpenSettingsDrawer,
  setIsOpenConfirmModal,
  setIsOpenConfirmAddressModal,
  setIsOpenBrushWidget,
  setIsOpenProgressModal,
  setIsOpenBuyProModal,
  setIsOpenBuyProPromoModal
} = modalSlice.actions;
export default modalSlice.reducer;
