import axios from 'axios';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { getGoogleClientId, getUtm } from 'utils/utils.js';

import { startLoading, stopLoading } from '../../redux/errorSlice';
import { store } from '../../redux/store';
import { SITE_NAME } from "../../main.jsx";

const state = store.getState();
const API_HOST = state.features.baseUrl;
console.log('API_HOST', API_HOST);

export async function savePayoutAddress(payoutAddress) {
  return axios.post(
    `${API_HOST}/api/v1/ref/settings`,
    { payoutAddress },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function claimProfit(amount) {
  return axios.post(
    `${API_HOST}/api/v1/ref/claim`,
    { amount },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function getPartnerInfo() {
  return axios.get(`${API_HOST}/api/v1/partner/info`);
}

export async function sendPartnerL2Rewards() {
  return axios.get(`${API_HOST}/api/v1/ref/rewards/l2`, {
    headers: {
      Authorization: Cookies.get('token'),
    },
  });
}

export async function sendUserRedeemRequest(code) {
  return axios.post(
    `${API_HOST}/api/v1/sub/redeem`,
    {
      code,
    },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export function sendUserLogin2Request(access_token) {
  if (access_token.indexOf('@nudify.online') === -1) access_token += '@nudify.online';
  return axios.post(
          `${API_HOST}/api/v1/login2`,
          Object.assign(
              {
                authMethod: 'ANONYMOUS_AUTH',
                token: access_token,
                inviteCode: Cookies.get('referral'),
                captchaToken: Cookies.get('captchaToken'),
                site: SITE_NAME,
                clientId: null,
                keitaroSubId: Cookies.get('keitaroSubId'),
                metricUserCode: getUserCode(),
              },
              getUtm()
          )
      );
}
export async function sendUserInfoRequest() {
  return axios.get(`${API_HOST}/api/v1/user/info`, {
    headers: {
      Authorization: Cookies.get('token'),
    },
  });
}

export async function getCaptchaBase64() {
    return axios.get(`${API_HOST}/api/v1/captcha`, {    });
}

export async function sendUserInfoCreate(info) {
  try {
    return await axios.post(`${API_HOST}/api/v1/user/info`, info, {
      headers: {
        Authorization: Cookies.get('token'),
      },
    });
  } catch (e) {
    console.error(e);
  }
}

export async function getUserFeatures() {
  return axios.get(
    `${API_HOST}/api/v1/features?site=${window.location.hostname}`,
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function getPlanInfo() {
  return axios.get(`${API_HOST}/api/v1/sub/info`, {
    headers: {
      Authorization: Cookies.get('token'),
    },
  });
}

export async function getPlanPrices() {
  return axios.get(`${API_HOST}/api/v1/sub/prices`, {
    // headers: {
    //   Authorization: Cookies.get('token').toString(),
    // },
  });
}

export async function cancelPlan() {
  return axios.post(`${API_HOST}/api/v1/sub/cancel`, null, {
    headers: {
      Authorization: Cookies.get('token'),
    },
  });
}

export async function deleteMessage(messageId) {
  return axios.post(
    `${API_HOST}/api/v1/user/message/remove`,
    Object.assign({
      id: messageId,
    }),
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function trialUser(captchaToken) {
  const clientId = await getGoogleClientId();

  return axios.post(
    `${API_HOST}/api/v1/login`,
    Object.assign(
      {
        site: SITE_NAME,
        token: '',
        authMethod: 'IP_TEST',
        inviteCode: Cookies.get('referral'),
        captchaToken: captchaToken,
        clientId: clientId,
        keitaroSubId: Cookies.get('keitaroSubId'),
        metricUserCode: getUserCode(),
      },
      getUtm()
    )
  );
}

export async function loginUser(email, password, captchaToken) {
  const clientId = await getGoogleClientId();

  return axios.post(
    `${API_HOST}/api/v1/login`,
    Object.assign(
      {
        site: SITE_NAME,
        token: '',
        authMethod: 'LOGIN_PASSWORD',
        inviteCode: Cookies.get('referral'),
        email: email.toString(),
        password: password.toString(),
        captchaToken: captchaToken,
        clientId: clientId,
        keitaroSubId: Cookies.get('keitaroSubId'),
        metricUserCode: getUserCode(),
      },
      getUtm()
    )
  );
}

export async function confirmMail(email, code) {
  return axios.post(`${API_HOST}/api/v1/confirm`, {
    email: email.toString(),
    code: code.toString(),
  });
}

export async function ketarioPay(subId, pay_type) {
  return axios.post('http://140.82.54.204/ba5bcb1/postback', {
    subid: subId,
    sub_id_1: pay_type,
  });
}

export function getUserCode() {
  let user_code = Cookies.get('user_code');
  if (!user_code) {
    user_code =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    Cookies.set('user_code', user_code);
  }
  return user_code;
}

export async function metricEntered() {
  return customMetric('visit');
}

export async function metricChooseAuth(authMethod) {
  return axios.post(`${API_HOST}/api/v1/metric`, {
    type: 'choose_auth',
    authMethod: authMethod,
    userCode: getUserCode(),
    referCode: Cookies.get('referral'),
  });
}

export async function customMetric(type) {
  return axios.post(`${API_HOST}/api/v1/metric`, {
    type: type,
    userCode: getUserCode(),
    referCode: Cookies.get('referral'),
  });
}

export async function bannerMetric({
  banner_type,
  utm_source,
  utm_medium,
  utm_campaign,
  utm_keyword,
  utm_content,
  utm_page_ref,
}) {
  return axios.post(`${API_HOST}/api/v1/banner/metric`, {
    type: banner_type,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_keyword,
    utm_content,
    utm_page_ref,
    userCode: getUserCode(),
    referCode: Cookies.get('referral'),
  });
}

export async function customAuthorizedMetric(type) {
  return axios.post(
    `${API_HOST}/api/v1/metric`,
    {
      type: type,
      userCode: getUserCode(),
      referCode: Cookies.get('referral'),
    },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function metricOpenPay(paySystem) {
  return axios.post(
      `${API_HOST}/api/v1/metric`,
      {
        type: 'open_pay',
        userCode: getUserCode(),
        referCode: Cookies.get('referral'),
        paymentType: paySystem
      },
      {
        headers: {
          Authorization: Cookies.get('token'),
        },
      }
  );
}

export async function processMelCheckout(selectedPlan, months) {
  try {
    const ret = await axios.post(
      `${API_HOST}/mel/checkout/${Cookies.get(
        'token'
      )}/${selectedPlan}/cards/?months=${months ? months : 1}`
    );
    return ret.data.url;
  } catch (e) {
    console.error(e);
  }
  return null;
}

export async function getUserPrompts() {
  return axios.get(`${API_HOST}/api/v1/user/prompts`, {
    headers: {
      Authorization: Cookies.get('token'),
    },
  });
}

export async function linkWithNudXAccount(authHash) {
  return axios.post(
    `${API_HOST}/api/v1/external/info`,
    { authHash },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function getUserPaymentsList(plan, months) {
  return axios.get(
    `${API_HOST}/api/v1/user/paymentslist?plan=${plan}&months=${months}`,
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function createCryptoPaymentViaCard(plan, months) {
    // const ret = await createCard2CryptoPaymentUrl(plan, months, "alchemypay");
    // if (ret?.data?.resultUrl) {
    //     ret.data.payUrl = ret.data.resultUrl;
    // }
    // return ret;
  return axios.post(
    `${API_HOST}/payment-address/${Cookies.get('token')}/${plan}/crypto?months=${months ? months : 1}`
  );
}

export async function createArkPaymentUrl(plan, months) {
  return axios.post(
    `${API_HOST}/api/v1/ark/invoice`,
    {
      plan: plan,
      months: months,
    },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function createInstaExchangePaymentUrl(plan, months) {
  const ret = await axios.post(
    `${API_HOST}/api/v1/instaxchange/invoice`,
    {
      plan: plan,
      months: months,
    },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
  return ret;
}

export async function recreatePayPal2Invoice(order) {
  try {
    const response = axios.post(
      `${API_HOST}/api/v1/paypal2/recreate`,
      {
        order: order,
      },
      {
        headers: {
          Authorization: Cookies.get('token'),
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error during creating PayPal custom payment URL:', error);
    throw error;
  }
}

export async function getLastArkOrderStatus() {
  try {
    const response = axios.post(
      `${API_HOST}/api/v1/ark/payment/last`,
      {},
      {
        headers: {
          Authorization: Cookies.get('token'),
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error during request last ark order:', error);
    throw error;
  }
}

export async function createPayPalCustomPaymentUrl(plan, months) {
  store.dispatch(startLoading());
  try {
    const response = axios.post(
      `${API_HOST}/api/v1/paypal2/invoice`,
      {
        plan: plan,
        months: months,
      },
      {
        headers: {
          Authorization: Cookies.get('token'),
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error during creating PayPal custom payment URL:', error);
    throw error;
  } finally {
    store.dispatch(stopLoading());
  }
}

export async function createStripe2CustomPaymentUrl(plan, months) {
    store.dispatch(startLoading());
    try {
        const response = axios.post(
            `${API_HOST}/api/v1/stripe2/invoice`,
            {
                plan: plan,
                months: months,
            },
            {
                headers: {
                    Authorization: Cookies.get('token'),
                },
            }
        );
        return response;
    } catch (error) {
        console.error('Error during creating PayPal custom payment URL:', error);
        throw error;
    } finally {
        store.dispatch(stopLoading());
    }
}

export async function createPTIMEAIPaymentUrl(plan, months) {
    store.dispatch(startLoading());
    try {
        const response = axios.post(
            `${API_HOST}/api/v1/ptimeai/invoice`,
            {
                plan: plan,
                months: months,
            },
            {
                headers: {
                    Authorization: Cookies.get('token'),
                },
            }
        );
        return response;
    } catch (error) {
        console.error('Error during creating PayPal custom payment URL:', error);
        throw error;
    } finally {
        store.dispatch(stopLoading());
    }
}

export async function createCard2CryptoPaymentUrl(plan, months, provider="auto") {
    store.dispatch(startLoading());
    try {
        const response = axios.post(
            `${API_HOST}/api/v1/c2c/invoice`,
            {
                plan: plan,
                months: months,
                provider: provider
            },
            {
                headers: {
                    Authorization: Cookies.get('token'),
                },
            }
        );
        return response;
    } catch (error) {
        console.error('Error during creating Card2Crypto custom payment URL:', error);
        throw error;
    } finally {
        store.dispatch(stopLoading());
    }
}

export async function createNFTPaymentUrl(plan, months) {
    store.dispatch(startLoading());
    try {
        const response = axios.post(
            `${API_HOST}/api/v1/p2p/invoice`,
            {
                plan: plan,
                months: months,
                provider: "NFT"
            },
            {
                headers: {
                    Authorization: Cookies.get('token'),
                },
            }
        );
        return response;
    } catch (error) {
        console.error('Error during creating Card2Crypto custom payment URL:', error);
        throw error;
    } finally {
        store.dispatch(stopLoading());
    }
}

export async function createVENMOPaymentUrl(payment_type, plan, months) {
    store.dispatch(startLoading());
    try {
        const response = axios.post(
            `${API_HOST}/api/v1/lux/invoice`,
            {
                plan: plan,
                months: months,
                type: payment_type
            },
            {
                headers: {
                    Authorization: Cookies.get('token'),
                },
            }
        );
        return response;
    } catch (error) {
        console.error('Error during creating Venmo custom payment URL:', error);
        throw error;
    } finally {
        store.dispatch(stopLoading());
    }
}

export async function createTelegramPaymentUrl(plan, months) {
    store.dispatch(startLoading());
    try {
        const response = axios.post(
            `${API_HOST}/api/v1/telegram/invoice`,
            {
                plan: plan,
                months: months,
            },
            {
                headers: {
                    Authorization: Cookies.get('token'),
                },
            }
        );
        return response;
    } catch (error) {
        console.error('Error during creating PayPal custom payment URL:', error);
        throw error;
    } finally {
        store.dispatch(stopLoading());
    }
}

export async function createQuantumIframePaymentUrl(
  plan,
  months,
  firstName = '',
  lastName = '',
  phoneNumber = '',
  address = '',
  country = '',
  city = '',
  state = '',
  region = '',
  zone = '',
  zip = ''
) {
  store.dispatch(startLoading());
  try {
    const response = axios.post(
      `${API_HOST}/api/v1/quantumi/invoice`,
      {
        plan: plan,
        months: months,
        addressInfo: {
          firstName,
          lastName,
          phoneNumber,
          address,
          country,
          city,
          state,
          region,
          zone,
          zip,
        },
      },
      {
        headers: {
          Authorization: Cookies.get('token'),
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error during creating PayPal custom payment URL:', error);
    throw error;
  } finally {
    store.dispatch(stopLoading());
  }
}
export async function createQuantumPaymentUrl(
  plan,
  months,
  firstName = '',
  lastName = '',
  phoneNumber = '',
  address = '',
  country = '',
  city = '',
  state = '',
  region = '',
  zone = '',
  zip = ''
) {
  store.dispatch(startLoading());
  try {
    const response = axios.post(
      `${API_HOST}/api/v1/quantum/invoice`,
      {
        plan: plan,
        months: months,
        addressInfo: {
          firstName,
          lastName,
          phoneNumber,
          address,
          country,
          city,
          state,
          region,
          zone,
          zip,
        },
      },
      {
        headers: {
          Authorization: Cookies.get('token'),
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error during creating PayPal custom payment URL:', error);
    throw error;
  } finally {
    store.dispatch(stopLoading());
  }
}
export async function createPayPortInvoce(plan, months) {
  store.dispatch(startLoading());
  try {
    const response = axios.post(
      `${API_HOST}/api/v1/payport/invoice`,
      {
        plan: plan,
        months: months,
      },
      {
        headers: {
          Authorization: Cookies.get('token'),
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error during creating PayPort invoice:', error);
    throw error;
  } finally {
    store.dispatch(stopLoading());
  }
}

export async function instagramParse(instagram) {
  return await axios.post(
    `${API_HOST}/api/v1/instagram?parsing_username=${instagram}`,
    {},
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function getUserPaypalAvailable() {
  return axios.get(`${API_HOST}/api/v1/sub/paypal/available`, {
    headers: {
      Authorization: Cookies.get('token'),
    },
  });
}

export async function getRecoveryCode(email, captchaToken) {
  return axios.post(`${API_HOST}/api/v1/email/resetpassword`, {
    captchaToken,
    email,
  });
}

export async function recoverPassword(email, code, newPassword, captchaToken) {
  return axios.post(`${API_HOST}/api/v1/email/changepassword`, {
    code,
    newPassword,
    email,
    captchaToken,
  });
}

export async function checkCode(email, code) {
  return axios.post(`${API_HOST}/api/v1/email/checkcode`, {
    code,
    email,
  });
}

export async function checkDeliveryEmail(email) {
  return axios.post(`${API_HOST}/api/v1/email/checkdelivery`, {
    email,
  });
}

export async function submitRating(rate) {
  return axios.post(
    `${API_HOST}/api/v1/image/rate`,
    {
      rate,
    },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function submitPhone(phone) {
  return axios.post(
    `${API_HOST}/api/v1/sub/trial`,
    {
      phone,
    },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}

export async function submitPhoneCode(verification_code) {
  return axios.post(
    `${API_HOST}/api/v1/sub/trial/confirm`,
    {
      verification_code,
    },
    {
      headers: {
        Authorization: Cookies.get('token'),
      },
    }
  );
}
